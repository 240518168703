import { BrowserRouter } from "react-router-dom";
import styles from "./App.module.scss";
import Main from "./layout/Main";

function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div>
  );
}
export default App;
