import React from "react";

interface FormattedTextProps {
  text: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  // Function to parse and format text
  const formatText = (text: string): string => {
    // Replace [b] tag with <strong>
    text = text.replace(/\[b\](.*?)\[\/b\]/g, "<strong>$1</strong>");

    // Replace [p] tag with <p>
    text = text.replace(/\[p\]/g, "<p>");

    // Replace [/p] tag with </p>
    text = text.replace(/\[\/p\]/g, "</p><br />");

    return text;
  };

  // Function to render formatted text
  const renderFormattedText = (): JSX.Element => {
    const formattedText = formatText(text);
    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <div>
      <p>{renderFormattedText()}</p>
    </div>
  );
};

export default FormattedText;
