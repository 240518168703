import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Gallery.module.scss";
import useScrollToTop from "../hooks/useScrollToTop";
import axios from "axios";
import FormattedText from "../components/FormattedText";

interface ApiData {
  _id: string;
  title: string;
  description: string;
  image: string;
}

function Gallery() {
  const [galleryList, setGalleryList] = useState<ApiData[] | null>(null);

  useScrollToTop();

  const imageBaseURL = "https://kinscapes.com/api/images/";
  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "https://kinscapes.com/api/fetch/gallery"; // Replace with your API URL

    // Fetch data from the API using Axios
    axios
      .get<{ data: ApiData[] }>(apiUrl)
      .then((response) => {
        setGalleryList(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div className={styles.container}>
      <h1>Gallery</h1>
      <div className={styles.gallery_grid}>
        {galleryList?.map((item) => (
          <Link
            key={item._id}
            to={`/gallery/${item._id}`}
            className={styles.item}
          >
            <img src={`${imageBaseURL}${item.image}`} alt="product" />
            <div className={styles.item_desc}>
              <h2>{item.title}</h2>

              <FormattedText
                text={`${item.description ? item.description : ""}`}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
export default Gallery;
