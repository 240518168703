import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {
  /* FaFacebookF,*/
  FaInstagram,
  FaTiktok,
  FaXTwitter,
} from "react-icons/fa6";

import styles from "./Main.module.scss";
import logo from "../assets/logo.png";
import logonm from "../assets/logonm.png";
import axios from "axios";
import Gallery from "../screens/Gallery";
import GalleryItem from "../screens/GalleryItem";

const Home = lazy(() => import("../screens/Home"));
const Shop = lazy(() => import("../screens/Shop"));
const About = lazy(() => import("../screens/About"));
const Admin = lazy(() => import("../screens/Admin"));

interface ApiResponse {
  data: {
    _id: string;
    email: string;
    phone: string;
    address: string;
  };
}

function Main() {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const location = useLocation();

  const toggleMobileMenu = () => {
    setShowMobileMenu((val) => !val);
  };

  useEffect(() => {
    // The API endpoint URL
    const apiUrl = "https://kinscapes.com/api/fetch/contact";

    // Fetch data from the API using Axios
    axios
      .get<ApiResponse>(apiUrl)
      .then((response) => {
        setData(response.data);

        localStorage.setItem("contact", JSON.stringify(response.data.data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  let iconStyles = { fill: "#fffdf5", fontSize: "1.8vw" };
  let iconStyles_a = { fill: "#fffdf5", fontSize: "7vw" };
  return (
    <div className={styles.container}>
      <nav className={`${styles.nav} ${styles.active_bg}`}>
        <div className={styles.contact}>
          <h2>{data ? data?.data.phone : ""}</h2>
          <p>{data?.data.email}</p>
        </div>

        <div onClick={toggleMobileMenu} className={styles.menu_icon__ctn}>
          <div className={styles.menu_icon}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        </div>

        <div
          className={`${styles.menu__ct} ${
            showMobileMenu === false && styles.close_menu
          }`}
        >
          <div onClick={toggleMobileMenu} className={styles.close}>
            <p>Close</p>
            <div className={styles.arrow} />
          </div>
          <Link
            to="/"
            onClick={toggleMobileMenu}
            className={`${styles.menu} ${
              location.pathname === "/" && styles.active_menu
            }`}
          >
            <p>Home</p>
          </Link>
          <Link
            to="/shop"
            onClick={toggleMobileMenu}
            className={`${styles.menu} ${
              location.pathname === "/shop" && styles.active_menu
            }`}
          >
            <p>Shop</p>
          </Link>
          <Link
            to="/gallery"
            onClick={toggleMobileMenu}
            className={`${styles.menu} ${
              location.pathname.includes("/gallery") ? styles.active_menu : ""
            }`}
          >
            <p>Gallery</p>
          </Link>
          <Link
            to="/about-us"
            onClick={toggleMobileMenu}
            className={`${styles.menu} ${
              location.pathname === "/about-us" && styles.active_menu
            }`}
          >
            <p>About Us</p>
          </Link>

          <img src={logo} alt="logo" />
        </div>
      </nav>
      {/* Render pages */}
      <main>
        <Suspense
          fallback={
            <div>
              <h4>Loading...</h4>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="shop" element={<Shop />} />
            {/* Move the GalleryItem route outside of the Gallery route */}
            <Route path="gallery" element={<Gallery />} />
            <Route path="gallery/:itemId" element={<GalleryItem />} />
            <Route path="about-us" element={<About />} />
            <Route path="admin" element={<Admin />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </main>
      <div className={styles.footer}>
        <div className={styles.logo_footer_ct}>
          <img src={logonm} alt="logo" />
          <Link
            to="/admin"
            className={`${styles.menu} ${
              location.pathname === "/admin" && styles.active_menu
            }`}
          >
            <p>Login</p>
          </Link>
        </div>
        <div className={styles.footer__ctn}>
          <h4>KINSCAPES</h4>
          <div className={styles.footer_details}>
            <div className={styles.footer_contact}>
              <div className={styles.ft_item}>
                <p>Phone</p>
                <span>{data?.data.phone}</span>
              </div>
              <div className={styles.ft_item}>
                <p>Email</p>
                <span>{data?.data.email}</span>
              </div>
              <div className={styles.ft_item}>
                <p>Address</p>
                <span>{data?.data.address}</span>
              </div>

              <div className={styles.social_links_a}>
                <a
                  href="https://www.instagram.com/kinscapes_natural_stones?igsh=aTcyeXZ6NTl0aWth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram style={iconStyles_a} />
                  <p>instagram</p>
                </a>
                <a
                  href="https://twitter.com/kin_scapes?t=HPZQCXaizGIzsTHKl7r59Q&s=09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter style={iconStyles_a} /> <p> twitter</p>
                </a>
                {/* <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF style={iconStyles_a} />
                  <p>facebook</p>
                </a> */}
                <a
                  href="https://vm.tiktok.com/ZMMfyN3r9/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok style={iconStyles_a} /> <p>tiktok</p>
                </a>
              </div>
            </div>
            <div className={styles.copyright}>
              <p>
                All Rights Reserved &copy; 2019 - {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.social_links}>
          <a
            href="https://www.instagram.com/kinscapes_natural_stones?igsh=aTcyeXZ6NTl0aWth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram style={iconStyles} />
            <p>instagram</p>
          </a>
          <a
            href="https://twitter.com/kin_scapes?t=HPZQCXaizGIzsTHKl7r59Q&s=09"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter style={iconStyles} /> <p> twitter</p>
          </a>
          {/* <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF style={iconStyles} />
            <p>facebook</p>
          </a> */}
          <a
            href="https://vm.tiktok.com/ZMMfyN3r9/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok style={iconStyles} /> <p>tiktok</p>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Main;
