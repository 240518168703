import { Link, useParams } from "react-router-dom";
import styles from "./GalleryItem.module.scss";
import useScrollToTop from "../hooks/useScrollToTop";
import { useEffect, useState } from "react";
import axios from "axios";
import FormattedText from "../components/FormattedText";

interface ApiData {
  _id: string;
  title: string;
  description: string;
  image: string;
}

const imageBaseURL = "https://kinscapes.com/api/images/";

function GalleryItem() {
  const { itemId } = useParams();
  const [item, setItem] = useState<ApiData | null>(null);
  const [message, setMessage] = useState<string>("");
  useScrollToTop();
  console.log(item);
  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(
          `https://kinscapes.com/api/fetch/gallery/${itemId}`
        );
        setItem(response.data.data);
      } catch (error) {
        console.error("Error fetching item:", error);
        setMessage("Error 404: Product Not Found!");
      }
    };

    fetchItem();
  }, [itemId]);

  return (
    <div className={styles.container}>
      <div className={styles.mhd}>
        <Link to={`/gallery`}>{`<<< Back to gallery`}</Link>

        <h2>{item ? item.title : message}</h2>
      </div>

      {item && (
        <div className={styles.p_content}>
          <div className={styles.p_image}>
            <img src={`${imageBaseURL}${item.image}`} alt="product" />
          </div>
          <div className={styles.p_details}>
            <FormattedText
              text={`${item.description ? item.description : ""}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default GalleryItem;
